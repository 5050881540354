<template>
  <!-- 充值明细 -->
  <div class="nav">
    <div class="titlehead d-flex">
      <div>充值金额</div>
      <div>充值价格</div>
      <div>订单号</div>
      <div>状态</div>
    </div>
    <div>
      <div class="titlenav d-flex f-13">
        <div>0.00</div>
        <div>0.00</div>
        <div>1345678962561</div>
        <div>已完成</div>
      </div>
      <div class="titlenav d-flex f-13">
        <div>0.00</div>
        <div>0.00</div>
        <div>1345678962561</div>
        <div>已完成</div>
      </div>
      <div class="titlenav d-flex f-13">
        <div>0.00</div>
        <div>0.00</div>
        <div>1345678962561</div>
        <div>已完成</div>
      </div>
      <div class="titlenav d-flex f-13">
        <div>0.00</div>
        <div>0.00</div>
        <div>1345678962561</div>
        <div>已完成</div>
      </div>
      <div class="titlenav d-flex f-13">
        <div>0.00</div>
        <div>0.00</div>
        <div>1345678962561</div>
        <div>已完成</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.nav {
  height: 100vh;
  @media (min-width: 550px) {
    width: 90%;
    margin: auto;
  }
  .titlehead {
    background-color: #683ab7;
    color: #74e804;
    font-weight: none;
    margin-top: 15px;
    padding: 15px 0;
    div {
      width: 24%;
      text-align: center;
    }
  }
  .titlenav {
    color: white;
    background-color: rgba(60, 60, 60, 0.39);
    padding: 15px 0;
    border-bottom: 1px solid #0c0d21;
    div {
      width: 24%;
      text-align: center;
    }
  }
}
</style>