import { render, staticRenderFns } from "./Top-up-Detail.vue?vue&type=template&id=6123a4db&scoped=true&"
import script from "./Top-up-Detail.vue?vue&type=script&lang=js&"
export * from "./Top-up-Detail.vue?vue&type=script&lang=js&"
import style0 from "./Top-up-Detail.vue?vue&type=style&index=0&id=6123a4db&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6123a4db",
  null
  
)

export default component.exports